<script setup lang="ts">
import type { PaginatorInfo, Raffle } from '#gql/default'
import useModal from '@base/composables/use-modal'
import { BUTTON_SIZE, BUTTON_THEME } from '@base/constants/button'
import { useRoute, useRouter } from 'vue-router'

interface Props {
  raffle: Raffle
}

const { raffle } = defineProps<Props>()
const emit = defineEmits(['close'])

const { t } = useI18n()
const { icons } = useDesign()
const route = useRoute()
const router = useRouter()

const { close, open, cssClasses, cssStyles } = useModal({
  onClose: () => {
    if (route.query.page) {
      router.replace({
        query: {
          ...route.query,
          page: undefined,
        },
      })
    }
    emit('close')
  },
})
open()

interface RaffleEntryData {
  id: string
  correctAnswer: boolean
  user: {
    id: string
    email: string
    firstName?: string
    lastName?: string
  }
}

const currentPage = ref(1)
const entries = ref<{ data: RaffleEntryData[] }>()
const paginatorInfo = ref<PaginatorInfo>()

const { fetchRaffleEntries } = await useRaffleAdmin()

async function loadPage(page: number) {
  if (
    page < 1 ||
    (paginatorInfo.value?.lastPage && page > paginatorInfo.value.lastPage)
  ) {
    return
  }

  currentPage.value = page
  const result = await fetchRaffleEntries({
    id: raffle.id,
    first: 15,
    page,
  })
  entries.value = result
  paginatorInfo.value = result.paginatorInfo
}

// Load first page on mount
onMounted(() => {
  loadPage(1)
})

defineOptions({
  name: 'RaffleParticipantsModal',
})
</script>

<template>
  <Teleport to="body">
    <div
      :class="cssClasses.container"
      :style="cssStyles.container"
      @click.self="close"
    >
      <div
        :class="cssClasses.modal"
        class="bg-light dark:bg-dark relative w-full max-w-xl rounded-lg p-0 shadow-xl"
      >
        <!-- Header -->
        <div
          class="border-neutral-light-3 dark:border-neutral-dark-1 relative mb-4 border-b p-2"
        >
          <h2
            class="pr-8 text-2xl font-bold"
            v-text="t('page.title', { name: raffle?.name })"
          />

          <Button
            :theme="BUTTON_THEME.TERTIARY"
            :size="BUTTON_SIZE.SM"
            :title="t('btn.closeThisWindow')"
            :icon="icons.CLOSE"
            :class="cssClasses.close"
            @click="close"
          />
        </div>

        <!-- Content -->
        <div class="py-4">
          <Paginator
            v-if="entries?.data"
            :top-nav="false"
            :paginator-info
            :next="() => loadPage(currentPage + 1)"
            :prev="() => loadPage(currentPage - 1)"
          >
            <div v-if="entries.data.length">
              <div
                v-for="(entry, index) in entries.data"
                :key="entry.id"
                class="dark:text-light text-dark bleed-bg flex items-center justify-between py-3"
                :class="
                  index % 2 === 0
                    ? 'bg-neutral-light-1 bleed-neutral-light-1 dark:bg-neutral-dark-1 dark:bleed-neutral-dark-1'
                    : ''
                "
              >
                <div class="text-sm">
                  {{ entry.user.email }}
                  <small
                    v-if="entry.user.firstName || entry.user.lastName"
                    class="inline font-semibold"
                  >
                    ({{ entry.user.firstName }} {{ entry.user.lastName }})
                  </small>
                </div>
                <div>
                  <Icon
                    :name="
                      entry.correctAnswer
                        ? 'material-symbols:check'
                        : 'material-symbols:close'
                    "
                    :class="
                      entry.correctAnswer ? 'text-green-600' : 'text-red-600'
                    "
                  />
                </div>
              </div>
            </div>
            <div v-else class="text-neutral-dark-1 py-4 text-center">
              {{ t('errors.noEntriesFound') }}
            </div>
          </Paginator>
        </div>
      </div>
      <div :class="cssClasses.backdrop" @click.self="close" />
    </div>
  </Teleport>
</template>

<i18n>
de:
  page:
    title: "Teilnehmer: {name}"
  btn:
    closeThisWindow: "Fenster schließen"
  errors:
    noEntriesFound: "Keine Teilnehmer gefunden"
</i18n>
