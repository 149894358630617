<script setup lang="ts">
import type { PaginatorInfo } from '#gql/default'

interface Props {
  paginatorInfo: PaginatorInfo
  urlPrefix?: string
  prev?: () => void
  next?: () => void
}

const props = defineProps<Props>()
const emit = defineEmits(['navigate'])

const { t } = useI18n()
const { icons } = useDesign()
const route = useRoute()
const router = useRouter()
const currentPage = computed(() => props.paginatorInfo.currentPage)
const lastPage = computed(() => props.paginatorInfo.lastPage)

function navigate(newPage: number) {
  router.push({
    ...route,
    query: {
      ...route.query,
      [getFilterUrlKey(URL_FILTER.PAGE, props.urlPrefix)]: newPage,
    },
  })

  emit('navigate')
}

function prevPage() {
  if (props.prev) {
    props.prev()
  }
  navigate(currentPage.value - 1)
}

function nextPage() {
  if (props.next) {
    props.next()
  }
  navigate(currentPage.value + 1)
}

defineOptions({
  name: 'PaginatorNav',
})
</script>

<template>
  <nav class="paginator-nav grid grid-cols-[auto,1fr,auto] items-center gap-2">
    <Button
      :theme="BUTTON_THEME.SECONDARY"
      :disabled="currentPage === 1"
      :icon="icons.CHEVRON_LEFT"
      @click="prevPage"
    />
    <p
      class="text-center"
      v-text="
        t('pageInfo', {
          current: currentPage,
          total: lastPage,
        })
      "
    />
    <Button
      :theme="BUTTON_THEME.SECONDARY"
      :disabled="currentPage === lastPage"
      :icon="icons.CHEVRON_RIGHT"
      @click="nextPage"
    />
  </nav>
</template>

<i18n>
de:
  pageInfo: "Seite {current} von {total}"
es:
  pageInfo: "Página {current} de {total}"
</i18n>
